// @flow
const styles = {
  button: {
    backgroundImage: "linear-gradient(112deg, #2cceb3, #2cce62)",
    color: "#ffffff",
    minWidth: "100px",
  },
};

export default styles;
