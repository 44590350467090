// @flow

const styles = {
  main: {
    margin: "0 auto",
    overflowX: "hidden",
    width: "100vw",
  },
  container: {
    maxWidth: "1080px",
    padding: "0 20px",
    margin: "0 auto",
    boxSizing: "border-box",
  },
  blockTitle: {
    marginBottom: "32px",
    textAlign: "center",
  },
};

export default styles;
