// @flow

import * as React from "react";

import TextField from "@material-ui/core/TextField";

class Field extends React.Component {
  state = {
    value: "",
  };

  componentDidUpdate(prevProps) {
    if (this.props.isModalOpened && prevProps.isModalOpened !== this.props.isModalOpened) {
      this._handleClearValue();
    }
  }

  _handleChange = (ev) => {
    this.setState({ value: ev.currentTarget.value });
  };

  _handleClearValue = () => this.setState({ value: "" });

  render() {
    const { props, state } = this;
    const { field, i } = props;

    return (
      <TextField
        key={`textField-${i}-id`}
        label={field.label}
        type={field.type}
        name={field.name}
        required={field.isRequired}
        onChange={this._handleChange}
        value={state.value}
      />
    );
  }
}

export default Field;
