// @flow

import * as React from "react";
import styles from "./IconsBlockStyles";
import Img from "gatsby-image";

// helpers
import { iconsBlockInfo } from "../../helpers/contentHelpers";

// material-ui components
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const IconsBlock = (props) => {
  const { classes } = props;
  return (
    <div className={classes.iconsContainer}>
      {iconsBlockInfo.map((icon, i) => (
        <div key={`icon-${i}-id`} className={classes.iconWithInfoWrapper}>
          <span className={classes.iconWrapper}>
            <Img
              fluid={props.data[icon.img].childImageSharp.fluid}
              className={classes.icon}
              style={{
                maxHeight: "72px",
                maxWidth: "72px",
              }}
              imgStyle={{
                maxHeight: "100%",
                maxWidth: "100%",
              }}
            />
          </span>
          <Typography variant="h6" className={classes.titleText}>
            {icon.title}
          </Typography>
          <Typography variant="subtitle1" className={classes.descriptionText}>
            {" "}
            {icon.description}
          </Typography>
        </div>
      ))}
    </div>
  );
};

export default withStyles(styles)(IconsBlock);
