// @flow

const styles = (theme) => ({
  headerContainer: {
    width: "100vw",
    height: "770px",
    boxSizing: "border-box",
    marginBottom: "80px",
    [theme.breakpoints.down("sm")]: {
      height: "730px",
      marginBottom: "36px",
    },
  },
  headerBackground: {
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    right: 0,
    margin: "auto",
    zIndex: -1,
    "& img": {
      objectFit: "cover",
      objectPosition: "left center !important",
      fontFamily: "'object-fit: cover; object-position: left center !important;'",
    },
  },
  contactFormContainer: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    right: 0,
    margin: "auto",
    padding: "56px 0",
  },
});

export default styles;
