// @flow
import MainLandingPage from "../components/MainLandingPage";
import { graphql } from "gatsby";

export default MainLandingPage;

export const iconsAbout = graphql`
  fragment iconsAbout on File {
    childImageSharp {
      fluid(maxHeight: 72) {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  }
`;

export const verticalCardIcons = graphql`
  fragment verticalCardIcons on File {
    childImageSharp {
      fluid(maxHeight: 32) {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  }
`;

export const horizontalCardIcons = graphql`
  fragment horizontalCardIcons on File {
    childImageSharp {
      fluid(maxWidth: 40) {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  }
`;

export const pageQuery = graphql`
  query {
    efficiencyIcon: file(relativePath: { eq: "efficiency-icon.png" }) {
      ...iconsAbout
    }
    securityIcon: file(relativePath: { eq: "security-icon.png" }) {
      ...iconsAbout
    }
    ecologicalIcon: file(relativePath: { eq: "ecological-icon.png" }) {
      ...iconsAbout
    }
    costEffectiveIcon: file(relativePath: { eq: "cost-effective-icon.png" }) {
      ...iconsAbout
    }
    match: file(relativePath: { eq: "match.png" }) {
      childImageSharp {
        fluid(maxHeight: 48) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    liveInformation: file(relativePath: { eq: "live-information.png" }) {
      ...verticalCardIcons
    }
    information: file(relativePath: { eq: "information.png" }) {
      ...verticalCardIcons
    }
    communications: file(relativePath: { eq: "communications.png" }) {
      ...verticalCardIcons
    }
    easyTransport: file(relativePath: { eq: "easy-transport.png" }) {
      ...horizontalCardIcons
    }
    locationPage: file(relativePath: { eq: "location-page.png" }) {
      ...verticalCardIcons
    }
    truckIcon: file(relativePath: { eq: "truck-icon.png" }) {
      ...horizontalCardIcons
    }
    chatIcon: file(relativePath: { eq: "chat-icon.png" }) {
      childImageSharp {
        fluid(maxHeight: 70) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    creditCardIcon: file(relativePath: { eq: "credit-card-icon.png" }) {
      childImageSharp {
        fluid(maxHeight: 36) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    documentIcon: file(relativePath: { eq: "document-icon.png" }) {
      childImageSharp {
        fluid(maxHeight: 48) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    map: file(relativePath: { eq: "map.png" }) {
      childImageSharp {
        fluid(maxHeight: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    trucknetLogo: file(relativePath: { eq: "trucknet-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 158, maxHeight: 24) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;
