// @flow
import * as React from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./ButtonStyles";
import MuiButton from "@material-ui/core/Button";

const Button = (props) => {
  const { classes } = props;
  return (
    <MuiButton
      {...props}
      className={props.className ? props.className : null}
      classes={!props.variant ? { root: classes.button } : null}>
      {props.children}
    </MuiButton>
  );
};

export default withStyles(styles)(Button);
