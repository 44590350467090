// @flow

const styles = (theme) => ({
  joinTheRevolutionContainer: {
    position: "relative",
    width: "100vw",
    marginTop: "-170px",
    boxSizing: "border-box",
    textAlign: "center",
    height: "710px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "-50px",
      height: "500px",
    },
  },
  joinTheRevolutionBackground: {
    position: "absolute",
    width: "100%",
    minHeight: "100%",
    top: 0,
    left: 0,
    zIndex: -1,
  },
  joinTheRevolutionTextContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    padding: "16px",
    margin: "260px auto 135px",
    [theme.breakpoints.down("xs")]: {
      margin: "58px auto 24px",
    },
  },
  joinInfoText: {
    margin: "-16px 0 40px",
  },
  joinClickText: {
    marginBottom: "24px",
  },
});
export default styles;
