// @flow
import MainLandingPage from "./MainLandingPage";
import { LionessProvider } from "lioness";
import { parse } from "qs";
import * as React from "react";
import messages from "../../i18n/translations.json";

class MainLandingPageContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { locale: "en" };
  }

  componentWillMount() {
    if (typeof window === "undefined") {
      return;
    }
    const queryParams = parse(window.location.search.replace("?", ""), { delimiter: "&" });
    if (queryParams.lang) {
      this.setState({ locale: queryParams.lang });
    } else {
      const userLocale = window.navigator.language.substring(0, 2);
      this.changeLocation(userLocale);
    }
  }

  changeLocation(locale) {
    if (typeof window === "undefined") {
      return;
    }
    window.location.assign(`${location}/?lang=${locale}`);
  }

  render() {
    return (
      <LionessProvider messages={messages} locale={this.state.locale}>
        <MainLandingPage data={this.props.data} />
      </LionessProvider>
    );
  }
}

export default MainLandingPageContainer;
