// @flow
import * as React from "react";
import { T } from "lioness";

/* eslint-disable max-len */

export const siteUrl = "welcome.trucknet.io";

export const phoneNumber = <T message="+44 207 048 2096" />;
export const content = (
  <T message="Trucknet is a B2B intermediation platform for the optimization of transport. It uses intelligent, automated freight and truck exchange to improve the efficiency and profitability of transportation companies while reducing the impact on the environment." />
);
export const formError = <T message="An error has occurred. Try Again!" />;
export const formSuccess = <T message="Thank you! We will call you back soon." />;
export const title = <T message="Do you want to optimize your transportation costs?" />;
export const formTitle = <T message="Receive an immediate response" />;
export const cardsBlockTitle = <T message="Reduce your empty journeys - An intelligent solution!" />;
export const formButtonLabel = <T message="Validate" />;
export const buttonSignUp = <T message="register" />;
export const orCall = <T message="or contact our customer service" />;
export const workingTime = <T message="Mon - Thu: 08:00 - 18:00, Fri: 8:00 - 13:00" />;
export const joinBlockTitle = <T message="JOIN INNOVATION!" />;
export const trucknetStepsTitle = <T message="How does Trucknet work?" />;
export const trucknetDescription = (
  <T message="Trucknet is spreading across Europe, get ahead of your competitors by joining our platform." />
);
export const trucknetActionDescription = <T message="Click below to receive additional information." />;
export const cardsInfo = [
  {
    icon: "match",
    title: <T message="Perfect match" />,
    text: (
      <T message="Trucknet's algorithms automatically find transport offers and requests that suit you and increase your profit potential." />
    ),
  },
  {
    icon: "liveInformation",
    title: <T message="Optimize your journeys" />,
    text: (
      <T message="Connect your onboard telematics systems, and TMS to Trucknet safely to automatically send and receive proposals for your empty journeys." />
    ),
  },
  {
    icon: "information",
    title: <T message="Information at a glance" />,
    text: (
      <React.Fragment>
        <p>
          <T message="Access your information and reporting tools with a single click on Trucknet's easy-to-use dashboard." />
        </p>
        <p>
          <T
            message="Quickly visualize the number of trips you have made or subcontracted, the gains obtained, your work schedule
          for the coming days and of course the green kilometers earned by reducing your impact on the environment
          through the use of Trucknet."
          />
        </p>
      </React.Fragment>
    ),
  },
  {
    icon: "communications",
    title: <T message="Communication is the key" />,
    text: (
      <T message="Trucknet puts you in touch and does not interfere with transport prices. You negotiate between carriers your rates and take the best offer for your company. The secured and secured transaction is made online on the platform with Crédit Mutuel-CIC bank." />
    ),
  },
  {
    icon: "easyTransport",
    title: <T message="Simplified transport management" />,
    text: (
      <React.Fragment>
        <p>
          <T message="Trucknet offers your company a schedule." />
        </p>
        <p>
          <T message="Plan your journeys by scheduling your vehicles and drivers via your telematics and Trucknet will optimize your returns or empty journeys by finding freight automatically. You will also locate your trucks or your freight at any time." />
        </p>
      </React.Fragment>
    ),
  },
  {
    icon: "locationPage",
    title: <T message="Geolocation set" />,
    text: (
      <T message="You can manage your fleet with ease! Know where your trucks or freight are at all times, receive notifications ofdelays and completed deliveries. communicate with your drivers directly via the Trucknet 'driver interface' application." />
    ),
  },
];

export const iconsBlockInfo = [
  {
    img: "efficiencyIcon",
    title: <T message="Efficiency" />,
    description: <T message="Reduce your empty trips automatically and immediately" />,
  },
  {
    img: "securityIcon",
    title: <T message="Security" />,
    description: <T message="Crédit Mutuel provides a secure payment solution" />,
  },
  {
    img: "ecologicalIcon",
    title: <T message="Ecological" />,
    description: (
      <T message="Trucknet Transport Optimization Solution Recognized by French Ministry of the Economy and Environmental Protection at COP 21" />
    ),
  },
  {
    img: "costEffectiveIcon",
    title: <T message="Profitable" />,
    description: <T message="Make each transport a profitable transport" />,
  },
];

export const textFieldsInfo = [
  {
    label: <T message={"Your name"} />,
    type: "text",
    name: "userName",
    isRequired: true,
  },
  {
    label: <T message={"Name of the company"} />,
    type: "text",
    name: "companyName",
    isRequired: true,
  },
  {
    label: <T message="Phone number" />,
    type: "tel",
    name: "phone",
    isRequired: true,
  },
  {
    label: <T message="Email (optional)" />,
    type: "email",
    name: "email",
    isRequired: false,
  },
];

export const trucknetSteps = [
  {
    header: <T message="Register" />,
    content: "button",
    isNextStepExist: true,
  },
  {
    header: <T message="Post your offers" />,
    content: "truckIcon",
    height: "40px",
    isNextStepExist: true,
  },
  {
    header: <T message="Automatic Match" />,
    content: "match",
    height: "48px",
    isNextStepExist: true,
  },
  {
    header: <T message="Chat-conversation" />,
    content: "chatIcon",
    height: "70px",
    isNextStepExist: true,
  },
  {
    header: <T message="Payment solution" />,
    content: "creditCardIcon",
    height: "36px",
    isNextStepExist: true,
  },
  {
    header: <T message="CMR" />,
    content: "documentIcon",
    height: "48px",
    isNextStepExist: true,
  },
  {
    header: <T message="Real time tracking" />,
    content: "map",
    height: "100px",
    isNextStepExist: false,
  },
];

/* eslint-enable max-len */
