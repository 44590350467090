// @flow
import * as React from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./ButtonLinkStyles";
import Button from "../Button";

import { buttonSignUp } from "../../helpers/contentHelpers";

class ButtonLink extends React.Component {
  _handleClick = () => {
    if (window) {
      window.scrollTo(0, 0);
    }
  };

  render() {
    const { props } = this;
    return (
      <Button className={props.className} onClick={this._handleClick}>
        {buttonSignUp}
      </Button>
    );
  }
}

export default withStyles(styles)(ButtonLink);
