// @flow

import * as React from "react";
import styles from "./ContactFormStyles";
import Button from "../Button";
import SubmitSuccessModal from "../SubmitSuccessModal";
import Field from "./Field";
import Img from "gatsby-image";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { withTranslators } from "lioness";
// material-ui components
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import { fetch as fetchPolyfill } from "cross-fetch";

// helpers
import {
  textFieldsInfo,
  formTitle,
  title,
  formButtonLabel,
  formError,
  orCall,
  workingTime,
  phoneNumber,
} from "../../helpers/contentHelpers";

class ContactForm extends React.Component {
  state = {
    isSubmitting: false,
    isModalOpened: false,
    value: "",
  };

  getPhoneNumberLink = () => {
    return `tel:${this.props.t("+44 207 048 2096")}`;
  };

  _handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.target;
    this._handleStartSubmitting();
    const subject = `New call back for company ${form.companyName.value}`;
    const text = `
    A new call back request from welcome.trucknet.io.\n

    Name: ${form.userName.value}\n
    Company name: ${form.companyName.value}\n
    Phone: ${form.phone.value}\n
    Email: ${form.email.value}
  `;

    let response;

    try {
      response = await fetchPolyfill("https://fvd69tpyab.execute-api.eu-west-1.amazonaws.com/production/send-form", {
        method: "POST",
        body: JSON.stringify({
          subject,
          text,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
    } catch (error) {
      alert(error.message, formError);
      this._handleStopSubmitting();
      return;
    }

    const isResponseSucceed = await this._isResponseSucceed(response);
    if (!isResponseSucceed) {
      this._handleResponseError(response);
      return;
    }
    this._handleStopSubmitting();
    this._showSuccessSendingMessage();
    window.ga && window.ga("send", "event", "form", "sent");
    window.gtag && window.gtag("event", "conversion", { send_to: "AW-768831641/klK-CKiVr5MBEJnhze4C" });
  };

  _handleCall = () => {
    window.ga && window.ga("send", "event", "call", "click");
  };

  _handleStartSubmitting = () => this.setState({ isSubmitting: true });

  _handleStopSubmitting = () => this.setState({ isSubmitting: false });

  _showSuccessSendingMessage = () => this.setState({ isModalOpened: true });

  _handleCloseModal = () => this.setState({ isModalOpened: false });

  _isResponseSucceed = (response) => {
    if (response.status >= 200 && response.status < 300) return true;
  };

  _handleResponseError = async (response) => {
    const error = await response.json();
    alert(error.message, formError);
    this._handleStopSubmitting();
  };

  render() {
    const { props, state } = this;
    const { classes } = props;

    return (
      <div className={classes.contactFormContainer} style={{ display: "none" }}>
        <Paper className={classes.contactForm}>
          <OutboundLink target="_blank" href="https://trucknet.io">
            <Img
              fluid={props.data.trucknetLogo.childImageSharp.fluid}
              className={classes.logo}
              style={{
                width: "158px",
                height: "24px",
              }}
              imgStyle={{
                maxHeight: "100%",
                maxWidth: "100%",
              }}
            />
          </OutboundLink>
          <Typography variant="h1" className={classes.title}>
            {title}
          </Typography>
          <Typography variant="body1" className={classes.formTitle}>
            {formTitle}
          </Typography>
          <form className={classes.fieldsContainer} onSubmit={this._handleSubmit}>
            {textFieldsInfo.map((field, i) => (
              <Field key={`textField-${i}-id`} field={field} isModalOpened={state.isModalOpened} />
            ))}

            <div className={classes.buttonContainer}>
              <Button className={classes.button} type="submit">
                {!state.isSubmitting ? formButtonLabel : <CircularProgress size={20} className={classes.spinner} />}
              </Button>
              <Typography variant="caption" className={classes.caption}>
                {orCall}
              </Typography>
              <a href={this.getPhoneNumberLink()} onClick={this._handleCall} className={classes.link}>
                <Button variant="outlined" color="secondary" className={classes.button}>
                  {phoneNumber}
                </Button>
              </a>
              <Typography variant="caption" className={[classes.caption, classes.workingTime].join(" ")}>
                {workingTime}
              </Typography>
            </div>
          </form>
        </Paper>
        {state.isModalOpened ? <SubmitSuccessModal handleClose={this._handleCloseModal} /> : null}
      </div>
    );
  }
}

export default withTranslators(withStyles(styles)(ContactForm));
