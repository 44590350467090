// @flow
import * as React from "react";
import styles from "./JoinTheRevolutionBlockStyles";
import ButtonLink from "../ButtonLink";
import BackgroundImage from "gatsby-image";
import { graphql, StaticQuery } from "gatsby";

// helpers
import {
  buttonSignUp,
  joinBlockTitle,
  trucknetDescription,
  trucknetActionDescription,
} from "../../helpers/contentHelpers";

// material-ui components
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const JoinTheRevolutionBlock = (props) => (
  <StaticQuery
    query={graphql`
      query {
        mapBackground: file(relativePath: { eq: "background-map.png" }) {
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={(data) => {
      const { classes } = props;
      const imageData = data.mapBackground.childImageSharp.fluid;
      return (
        <div className={classes.joinTheRevolutionContainer}>
          <BackgroundImage className={classes.joinTheRevolutionBackground} fluid={imageData} />
          <div className={props.container}>
            <div className={classes.joinTheRevolutionTextContainer}>
              <Typography variant="h3" className={props.blockTitle}>
                {joinBlockTitle}
              </Typography>
              <Typography variant="subtitle1" className={classes.joinInfoText}>
                {trucknetDescription}
              </Typography>
              <Typography variant="subtitle1" className={classes.joinClickText}>
                {trucknetActionDescription}
              </Typography>
              <ButtonLink>{buttonSignUp}</ButtonLink>
            </div>
          </div>
        </div>
      );
    }}
  />
);

export default withStyles(styles)(JoinTheRevolutionBlock);
