// @flow

const styles = {
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  messageContainer: {
    maxWidth: "90%",
    padding: "64px",
    textAlign: "center",
    position: "relative",
  },
  closeButton: {
    position: "absolute",
    right: "4px",
    top: "4px",
  },
};

export default styles;
