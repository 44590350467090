// @flow

import * as React from "react";
import styles from "./HowTrucknetWorksBlockStyles";
import ButtonLink from "../ButtonLink";
import Img from "gatsby-image";

// helpers
import { trucknetSteps, buttonSignUp, trucknetStepsTitle } from "../../helpers/contentHelpers";

// material-ui components
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

// material-ui icons
import NavigateNext from "@material-ui/icons/NavigateNext";

const HowTrucknetWorksBlock = (props) => {
  const { classes } = props;
  return (
    <div className={classes.trucknetStepsBlock}>
      <Typography variant="h3" className={props.blockTitle}>
        {trucknetStepsTitle}
      </Typography>
      <div className={classes.stepsContainer}>
        {trucknetSteps.map((stepInfo, i) => (
          <div key={`step-${i}-id`} className={classes.step}>
            <Card className={classes.stepCard}>
              <CardHeader
                className={classes.header}
                title={
                  <Typography className={classes.headerTitle} variant="subtitle1">
                    {i + 1}. {stepInfo.header}
                  </Typography>
                }
              />
              <CardContent className={classes.stepCardContentContainer}>
                {stepInfo.content === "button" ? (
                  <ButtonLink>{buttonSignUp}</ButtonLink>
                ) : (
                  <span className={classes.contentWrapper}>
                    <Img
                      fluid={props.data[stepInfo.content].childImageSharp.fluid}
                      className={classes.content}
                      style={{
                        height: stepInfo.height,
                      }}
                      imgStyle={{
                        height: stepInfo.height,
                      }}
                    />
                  </span>
                )}
              </CardContent>
            </Card>
            {stepInfo.isNextStepExist ? (
              <NavigateNext className={classes.nextStepIcon} />
            ) : (
              <div className={classes.nextStepIcon} />
            )}
          </div>
        ))}
      </div>
      <ButtonLink className={classes.button}>{buttonSignUp}</ButtonLink>
    </div>
  );
};

export default withStyles(styles)(HowTrucknetWorksBlock);
