// @flow
import { shadow } from "../../helpers/stylesHelpers";

const styles = {
  infoCardsBlock: {
    textAlign: "center",
  },
  infoCardsContainer: {
    display: "flex",
    flexWrap: "wrap",
    margin: "28px -20px",
  },
  infoCard: {
    width: "320px",
    height: "auto",
    textAlign: "left",
    margin: "20px",
    padding: "24px",
    boxShadow: shadow,
    boxSizing: "border-box",
    flex: "auto",
  },
  infoCardTitleContainer: {
    display: "flex",
    alignItems: "center",
  },
  infoCardTitle: {
    lineHeight: 1.4,
    fontWeight: "bold",
  },
  cardText: {
    margin: "16px 0",
    lineHeight: 1.5,
    "& *": {
      margin: 0,
    },
  },
  cardHeader: {
    padding: 0,
    minHeight: "56px",
    maxWidth: "272px",
    display: "flex",
    alignItems: "flex-start",
  },
  cardContent: {
    margin: 0,
    padding: 0,
  },
  infoCardIconWrapper: {
    width: "40px",
    height: "32px",
    marginRight: "18px",
  },
  infoCardIcon: {
    maxWidth: "100%",
    maxHeight: "100%",
    "& img": {
      maxWidth: "100%",
      maxHeight: "100%",
      width: "auto !important",
    },
  },
};
export default styles;
