// @flow

import * as React from "react";
import styles from "./SubmitSuccessModalStyles";

// material-ui components
import { withStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";

// material-ui icons
import Close from "@material-ui/icons/Close";

// helpers
import { formSuccess } from "../../helpers/contentHelpers";

const SubmitSuccessModal = (props) => {
  const { classes } = props;
  return (
    <Modal open className={classes.modal} onBackdropClick={props.handleClose}>
      <Paper className={classes.messageContainer}>
        <IconButton className={classes.closeButton} onClick={props.handleClose}>
          <Close />
        </IconButton>
        <Typography variant="h6">{formSuccess}</Typography>
      </Paper>
    </Modal>
  );
};

export default withStyles(styles)(SubmitSuccessModal);
