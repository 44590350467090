// @flow

const styles = (theme) => ({
  contactFormContainer: {
    width: "100%",
    display: "flex !important",
    justifyContent: "flex-end",
    position: "absolute",
    boxSizing: "border-box",
    top: 0,
    right: 0,
    margin: "56px 16px",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      margin: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "16px 0",
    },
  },
  contactForm: {
    width: "100%",
    maxWidth: "400px",
    padding: "32px 48px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      opacity: 0.9,
    },
  },
  fieldsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flex: "auto",
  },
  logo: {
    width: "158px",
    height: "24px",
  },
  title: {
    fontWeight: 500,
    letterSpacing: "0.3px",
    lineHeight: 1.18,
    marginTop: "24px",
  },
  formTitle: {
    marginTop: "16px",
    fontWeight: 500,
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "36px -4px -4px",
  },
  button: {
    minWidth: "150px",
    margin: "4px",
  },
  caption: {
    textAlign: "center",
    margin: "4px 0",
  },
  workingTime: {
    width: "100%",
    maxWidth: "160px",
  },
  link: {
    textDecoration: "none",
  },
  spinner: {
    color: "#ffffff",
  },
});
export default styles;
