// @flow

import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#1f3239",
    },
    secondary: {
      main: "#2CCE62",
    },
  },
  typography: {
    useNextVariants: true,
    h1: {
      fontSize: "34px",
      lineHeight: 1.18,
      letterSpacing: 0.3,
    },
    h3: {
      fontSize: "34px",
      lineHeight: 1.18,
      letterSpacing: 0.3,
    },
    subtitle1: {
      fontSize: "14px",
    },
  },
});

export default theme;
