// @flow

const styles = (theme) => ({
  iconsContainer: {
    display: "flex",
    justifyContent: "space-around",
    flexWrap: "wrap",
    margin: "0 -16px",
    marginBottom: "124px",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "56px",
    },
  },
  iconWithInfoWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "200px",
    margin: "16px",
  },
  iconWrapper: {
    width: "72px",
    height: "72px",
  },
  icon: {
    maxWidth: "100%",
    maxHeight: "100%",
    marginBottom: "16px",
    "& img": {
      maxHeight: "100%",
      maxWidth: "100%",
      width: "auto !important",
      right: 0,
      margin: "0 auto",
    },
  },
  titleText: {
    lineHeight: "28px",
    marginBottom: "16px",
  },
  descriptionText: {
    textAlign: "center",
    maxWidth: "100%",
  },
});

export default styles;
