// @flow
import * as React from "react";
import styles from "./MainLandingPageStyles";
import Header from "../Header";
import ContactForm from "../ContactForm";
import IconsBlock from "../IconsBlock";
import CardsBlock from "../CardsBlock";
import JoinTheRevolutionBlock from "../JoinTheRevolutionBlock";
import HowTrucknetWorksBlock from "../HowTrucknetWorksBlock";
import { Helmet } from "react-helmet";
import theme from "../../Theme";
import objectFitImages from "object-fit-images";
import { withTranslators } from "lioness";

// helpers
import { title, siteUrl, content } from "../../helpers/contentHelpers";

// images
import mockups from "../../images/mockups.png";

// material-ui components
import { withStyles, MuiThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

class MainLandingPage extends React.Component {
  componentDidMount() {
    objectFitImages();
  }

  render() {
    const { props } = this;
    const { classes } = props;
    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <div className={classes.main}>
          <Helmet>
            <title>{props.t("Do you want to optimize your transportation costs?")}</title>
            <html lang="fr" />
            <link rel="canonical" href={siteUrl} />
            <meta name="docsearch:version" content="2.0" />
            <meta name="viewport" content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover" />
            <meta property="og:url" content={siteUrl} />
            <meta name="description" content={content} />
            <meta property="og:type" content="website" />
            <meta property="og:locale" content="en" />
            <meta property="og:site_name" content={title} />
            <meta property="og:image" content={`${siteUrl}${mockups}`} />
            <meta property="og:image:width" content="512" />
            <meta property="og:image:height" content="512" />

            <meta name="twitter:card" content="summary" />
            <link href="https://fonts.googleapis.com/css?family=Roboto:300,400,500" rel="stylesheet" async />
            <script async src="https://www.googletagmanager.com/gtag/js?id=AW-768831641" />
            <script>
              {`
              window.dataLayer = []; function gtag(){window.dataLayer.push(arguments)}
              gtag('js', new Date()); gtag('config', 'AW-768831641');
              `}
            </script>
          </Helmet>
          <Header container={classes.container}>
            <div className={classes.container}>
              <ContactForm data={props.data} />
            </div>
          </Header>
          <div className={classes.container}>
            <IconsBlock data={props.data} />
            <CardsBlock data={props.data} />
          </div>
          <JoinTheRevolutionBlock container={classes.container} blockTitle={classes.blockTitle} />
          <div className={classes.container}>
            <HowTrucknetWorksBlock data={props.data} blockTitle={classes.blockTitle} />
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

export default withTranslators(withStyles(styles)(MainLandingPage));
