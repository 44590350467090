// @flow

import * as React from "react";
import styles from "./HeaderStyles";
import BackgroundImage from "gatsby-image";
import { graphql, StaticQuery } from "gatsby";

// material-ui components
import { withStyles } from "@material-ui/core/styles";

const Header = (props) => (
  <StaticQuery
    query={graphql`
      query {
        headerImage: file(relativePath: { eq: "background.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={(data) => {
      const { classes } = props;
      const imageData = data.headerImage.childImageSharp.fluid;
      return (
        <div className={classes.headerContainer} style={{ minHeight: "580px" }}>
          <BackgroundImage fluid={imageData} className={classes.headerBackground} backgroundColor={"#ffffff"} />
          <div className={[classes.contactFormContainer, props.container].join(" ")}>{props.children}</div>
        </div>
      );
    }}
  />
);

export default withStyles(styles)(Header);
