// @flow
import * as React from "react";
import styles from "./CardsBlockStyles";
import Img from "gatsby-image";

// helpers
import { cardsInfo, cardsBlockTitle } from "../../helpers/contentHelpers";

// material-ui components
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";

const CardsBlock = (props) => {
  const { classes } = props;
  return (
    <div className={classes.infoCardsBlock}>
      <Typography variant="h3" className={classes.blockTitle}>
        {cardsBlockTitle}
      </Typography>
      <div className={classes.infoCardsContainer}>
        {cardsInfo.map((card, i) => (
          <Card key={`card-${i}-id`} className={classes.infoCard}>
            <CardHeader
              className={classes.cardHeader}
              title={
                <span className={classes.infoCardTitleContainer}>
                  {/* <img src={card.icon} alt="" className={classes.infoCardLogo} /> */}
                  <span className={classes.infoCardIconWrapper}>
                    <Img fluid={props.data[card.icon].childImageSharp.fluid} className={classes.infoCardIcon} />
                  </span>
                  <Typography variant="h6" className={classes.infoCardTitle}>
                    {card.title}
                  </Typography>
                </span>
              }
            />
            <CardContent classes={{ root: classes.cardContent }}>
              <Typography variant="subtitle1" className={classes.cardText}>
                {card.text}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default withStyles(styles)(CardsBlock);
