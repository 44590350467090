// @flow
import { shadow } from "../../helpers/stylesHelpers";

const styles = {
  trucknetStepsBlock: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  stepsContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    marginRight: "-56px",
    width: "calc(100% + 56px)",
  },
  step: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
  },
  stepCard: {
    width: "205px",
    height: "148px",
    margin: "20px 0",
    display: "flex",
    flexDirection: "column",
    boxShadow: shadow,
  },
  header: {
    backgroundColor: "#e9faef",
    padding: "12px 16px",
    height: "48px",
    maxWidth: "100%",
  },
  headerTitle: {
    lineHeight: "24px",
  },
  nextStepIcon: {
    width: "24px",
    height: "24px",
    margin: "16px",
  },
  stepCardContentContainer: {
    flex: "auto",
    padding: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  button: {
    margin: "20px 0 80px",
  },
  contentWrapper: {
    width: "100%",
    height: "100%",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    width: "100%",
    height: "100%",
    "& img": {
      maxWidth: "100%",
      maxHeight: "100%",
      width: "auto !important",
      right: 0,
      margin: "auto",
    },
  },
};

export default styles;
